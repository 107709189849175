// The legacy methods have been converted to TypeScript, with the decision made to retain their original logic.
// Since these methods were not typed previously, they included logic to verify if a value exists.
// To maintain this logic, the values have been typed as `unknown`

export const removeMarkdownFromText = (text: unknown): string =>
  typeof text === 'string'
    ? text
        // Remove headers (#, ##, ###)
        .replace(/#{1,6}\s+(.*)/g, '$1')

        // Remove bold (**text**)
        .replace(/\*\*([^*]+)\*\*/g, '$1')

        // Remove italic (*text*)
        .replace(/\*([^*]+)\*/g, '$1')

        // Remove images (![alt](src))
        .replace(/!\[.*?\]\(.*?\)/g, '')

        // Remove links ([text](url))
        .replace(/\[([^\[\]]*)\]\([^\)\(]*\)/g, '$1')
    : ''

export const upper = (text: unknown): string => (typeof text === 'string' ? text.toUpperCase() : '')

export const capitalize = (text: unknown): string =>
  typeof text === 'string' ? text.charAt(0).toUpperCase() + text.slice(1) : ''

export const truncate = (text: unknown, maxLength: number): string => {
  if (typeof text === 'string') {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text
  }

  return ''
}

export const getHtmlCharCode = (char: string): string => {
  if (char.length === 0) {
    return ''
  }

  const code = String(char.charCodeAt(0)).padStart(4, '0')
  return `&#${code};`
}

export const sanitize = (text: string): string => {
  // eslint-disable-next-line no-control-regex
  return text.replace(/(?![0-9A-Za-z])[\u0000-\u00FF]/g, getHtmlCharCode)
}

export const sanitizeBrackets = (text: string): string => text.replace(/[<>]/g, getHtmlCharCode)

export const sanitizeObjectKey = (obj: Record<string, unknown>, key: string): Record<string, unknown> => {
  const keys: string[] = key.split('.')

  if (keys.length > 1) {
    const currentKey = keys.shift()

    if (!currentKey) {
      return obj
    }

    const currentObj = obj[currentKey]

    if (!currentObj) {
      return obj
    }

    if (Array.isArray(currentObj)) {
      return {
        ...obj,
        [currentKey]: currentObj.map((o) => sanitizeObjectKey(o, keys.join('.'))),
      }
    }

    if (typeof currentObj === 'object') {
      return {
        ...obj,
        [currentKey]: sanitizeObjectKey(currentObj as Record<string, unknown>, keys.join('.')),
      }
    }
  }

  const currentObj = obj[key]

  if (!currentObj) {
    return obj
  }

  if (Array.isArray(currentObj)) {
    return {
      ...obj,
      [key]: currentObj.map((o) => (typeof o === 'string' ? sanitize(o) : o)),
    }
  }

  if (typeof currentObj === 'string') {
    return {
      ...obj,
      [key]: sanitize(currentObj),
    }
  }

  return obj
}

export const sanitizeObjectKeys = (obj: Record<string, unknown>, keys: string[]) => {
  let result: Record<string, unknown> = { ...obj }
  keys.forEach((key) => {
    result = sanitizeObjectKey(result, key)
  })
  return result
}

export const cleanAccents = (text: string): string => text.normalize('NFKD').replace(/\p{Diacritic}/gu, '')
